import styled from 'styled-components';
import Banner from '~/assets/Banner-mobile-topo/banner1-julho2024-mobile.jpg';
import { imgsMobile } from '~/assets/Banner-mobile-topo';

export const Container = styled.div`
  background-color: #f5f5f5;
  display: none;

  h3 {
    font-size: 0.8rem;
  }
  h2 {
    font-size: 1.4rem;
  }
  h5 {
    font-size: 1.1rem;
  }
  .dark {
    filter: invert();
  }
  .font-size-4 {
    font-size: 1rem;
    font-weight: 300;
  }
  .legenda-banner {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .banner-topo-mobile {
    background-image: url(${imgsMobile[0]});
  }
  .banner-topo-mobile {
    background-image: url(${imgsMobile[1]});
  }
  .banner-topo-mobile {
    background-image: url(${imgsMobile[2]});
  }
  .banner-topo-mobile {
    background-image: url(${imgsMobile[3]});
  }
  .banner-topo-mobile {
    background-image: url(${imgsMobile[4]});
  }
  .banner-topo-mobile {
    background-image: url(${imgsMobile[5]});
  }
  .banner-topo-mobile {
    background-image: url(${imgsMobile[6]});
  }
  .produto-topo-desc {
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
  }
  .seta-prev img,
  .seta-next img {
    padding: 6px;
    filter: none;
  }
  .desc-biondina {
    max-width: 270px;
  }
  .btn-topo {
    border-radius: 0;
    border: 2px solid #fff;
    font-weight: bold;
    padding: 1rem 2rem;
    font-size: 1.1rem;
    color: #fff;
    transition: all 1s ease;
    margin-top: 1rem;
  }
  .btn-topo:hover {
    border-radius: 40px;
    background: #000;
    transition: all 1s ease;
    border: 2px solid #000;
  }
  .banner-topo-mobile {
    background-image: url(${Banner});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    color: #fff;
    min-height: 400px;
    height: 40vh;
    max-height: 500px;
    transition: background-image 1s ease;
  }

  .fadein {
    display: flex !important;
    min-height: 400px;
    height: 40vh;
    max-height: 500px;
  }

  .seta-prev,
  .seta-next {
    z-index: 2;
    color: #fff;
    border-radius: 100%;
    height: 30px;
    min-width: 30px;
    border: 1px solid #fff;
    opacity: 0.4;
  }
  @media (max-width: 768px) {
    h2 {
      margin-top: 2rem !important;
      font-size: 1.8rem !important;
      max-width: 100%;
    }
    p {
      margin-bottom: 1rem !important;
    }
    .btn-topo {
      display: none;
    }
    .conheca {
      height: 100%;
      text-align: center;
      justify-content: center;
    }
    .conheca div {
      margin: 0 0 0 !important;
      padding: 0 !important;
      width: 100%;
    }
    #btn-makeup,
    #btn-skinCare {
      display: inline;
    }
    #skinCare,
    #makeup {
      padding-left: 0 !important;
      text-align: initial;
    }
  }

  @media (max-width: 567px) {
    display: block;
    .profissional-titulo,
    .dermoglam-titulo {
      font-size: 2.6rem !important;
    }
    .banner-topo-mobile {
      min-height: 480px;
      height: 40vh;
      max-height: 500px;
      background-size: auto 100%;
    }
  }
  @media (max-width: 396px) {
    .profissional-titulo,
    .dermoglam-titulo {
      font-size: 2.4rem !important;
    }

    .banner-topo-mobile {
      min-height: 430px;
      background-size: auto 100%;
    }
  }
`;
