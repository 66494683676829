const img = require('./booster.png');
const img2 = require('./nightCream.png');
const img3 = require('./serumAntiox.png');
const img4 = require('./serumFacial.png');
const img5 = require('./baseLiquida.jpeg');
const img6 = require('./gelEsfoliante.jpeg');
const img7 = require('./mascaraCilios2.jpg');
const img8 = require('./lumi-glow.jpg');

export const imgs = {
  booster: img,
  nightCream: img2,
  serumAntiox: img3,
  serumFacial: img4,
  baseLiquida: img5,
  gelEsfoliante: img6,
  mascaraCilios: img7,
  lumiGlow: img8,
};
