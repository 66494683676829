import React from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { Container } from './styles';
import logo from '~/assets/logo_header_neg-2x.png';

function Footer() {
  return (
    <Container>
      <div className="container fundo p-0">
        <h2 className="h5 text-center font-weight-bold p-4">
          Conheça a nossa empresa de cosméticos!
        </h2>
        <div className="d-flex mobile-block px-2">
          <div className="col p-4 my-2 border-right mobile-border">
            <img src={logo} alt="Logo Anaconda" className="my-4 logo" />
            <p className="text-justify desc-anaconda">
              A Anaconda Cosméticos é uma empresa 100% nacional com tecnologias
              francesas, alemãs e americanas de alta performance e distribuição
              em todo território nacional.
            </p>
          </div>

          <div className="col p-4 ml-auto mobile-mt-0">
            <div className=" p-2 formas-contato">
              <h6 className="col-12 p-0 m-0">Endereço</h6>
              <p>
                Av. Celso Garcia, 676 - Brás
                <br /> São Paulo – SP
                <br />
                CEP: 03014-000
              </p>
              <h6 className="col-12 p-0 m-0">Telefone</h6>
              <a href="tel:+551126187766" className="text-black">
                {' '}
                011 – 2618-7766
              </a>
              <h6 className="col-12 p-0 m-0">Email</h6>
              <a
                href="mailto:fabrica.anaconda@gmail.com"
                className="text-break text-black"
              >
                {' '}
                fabrica.anaconda@gmail.com
              </a>
            </div>
            <div className="">
              <a
                href="https://www.facebook.com/Anaconda-Cosm%C3%A9ticos-109160597287290/"
                className=""
              >
                <FaFacebookF size={24} color="#000" />
              </a>
              <a
                href="https://www.instagram.com/anaconda.cosmeticosoficial/?hl=pt-br"
                className=" ml-4"
              >
                <FaInstagram size={24} color="#000" />
              </a>
            </div>
          </div>
        </div>
        <div className="mt-4 text-center">
          <small>@ ANACONDA COSMÉTICOS | TODOS OS DIREITOS RESERVADOS</small>
        </div>
      </div>
    </Container>
  );
}

export default Footer;
