import styled from 'styled-components';
import Banner from '~/assets/Banner_desk_topo/banner1-julho2024.jpg';

export const Container = styled.div`
  background-color: #f5f5f5;
  display: block;
  h3 {
    font-size: 0.8rem;
  }
  h2 {
    font-size: 1.4rem;
  }
  h5 {
    font-size: 1.1rem;
  }

  .logo {
    width: 162px;
    max-width: 120%;
  }

  .font-size-4 {
    font-size: 3rem;
    font-weight: bold;
  }
  .font-size-5 {
    font-size: 5rem;
    font-weight: bold;
  }
  .font-size-6 {
    margin-left: 110px;
    font-size: 4rem;
    color: #6d501e;
  }
  .font-size-7 {
    font-size: 2rem;
    font-weight: bold;
    margin: 0px;
  }
  .font-size-8 {
    font-size: 3rem;
    font-weight: bold;
    color: #666a68;
  }

  .titulo3 {
    color: #b38070;
  }
  .legenda-banner {
    font-size: 23px;
    font-weight: 600;
  }
  .cera-mode {
    padding-left: 0px;
    margin-left: 0px;
  }
  .produto-topo-desc {
    font-weight: 200;
  }
  .produto-topo-desc-2 {
    font-weight: 400;
    margin-left: 110px;
    color: #8b6930;
  }

  .seta-prev img,
  .seta-next img {
    padding: 6px;
    filter: none;
  }
  .btn-topo {
    border-radius: 0;
    border: 2px solid #fff;
    font-weight: bold;
    padding: 0.8rem 1.8rem;
    color: #fff;
    transition: all 1s ease;
  }
  .btn-new-banner {
    left: 30%;
    bottom: 30px;
    transform: translateX(-50%);
  }
  .pink {
    border: 2px solid #bf6c7c;
    color: #bf6c7c;
  }
  .gold {
    border: 2px solid #8a6264;
    color: #8a6264;
  }
  .btn-topo-2 {
    border-radius: 0;
    border: 2px solid #fff;
    font-weight: bold;
    padding: 0.8rem 1.8rem;
    color: #fff;
    transition: all 1s ease;
    margin-left: 110px;
    color: #8b6930;
  }
  .btn-topo-3 {
    border-radius: 0;
    border: 2px solid #fff;
    font-weight: bold;
    padding: 0.8rem 1.8rem;
    color: #fff;
    transition: all 1s ease;
    color: #666a68;
  }
  .btn-topo:hover {
    border-radius: 40px;
    background: #000;
    transition: all 1s ease;
    border: 2px solid #000;
  }
  .btn-topo-2:hover {
    border-radius: 40px;
    background: #000;
    transition: all 1s ease;
    border: 2px solid #000;
    color: #fff;
  }
  .btn-topo-3:hover {
    border-radius: 40px;
    background: #000;
    transition: all 1s ease;
    border: 2px solid #000;
    color: #fff;
  }
  .banner-topo {
    background-image: url(${Banner});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #fff;
    height: 500px;
    transition: all 0.7s ease-out;
  }

  .fadein {
    display: flex !important;
  }

  .seta-prev,
  .seta-next {
    z-index: 2;
    color: #fff;
    border-radius: 100%;
    height: 30px;
    min-width: 30px;
    background: rgb(0 0 0 / 75%);
    border: 2px solid #fff;
    opacity: 1;
  }
  @media (max-width: 1399px) {
    .banner-topo {
      min-height: 350px;
      height: 40vh;
      max-height: 500px;
    }
    .font-size-4 {
      font-size: 3rem;
      font-weight: bold;
    }
    .font-size-5 {
      font-size: 3rem;
      font-weight: bold;
    }
    .font-size-6 {
      margin-left: 0px;
      font-size: 3rem;
      font-weight: bold;
      color: #6d501e;
    }
    .produto-topo-desc-2,
    .btn-topo-2 {
      margin-left: 0;
    }
    .font-size-7 {
      font-size: 3rem;
      font-weight: bold;
      margin: 0px;
    }
    .font-size-8 {
      font-size: 3rem;
      font-weight: bold;
      color: #666a68;
    }
    .legenda-banner {
      font-size: 16px;
      font-weight: 500;
    }
  }
  @media (max-width: 768px) {
    .banner-topo {
      background-position: 62%;
    }
    h2 {
      margin-top: 2rem !important;
      font-size: 2.8rem !important;
      max-width: 100%;
    }
    p {
      margin-bottom: 1rem !important;
    }
    .btn-topo {
      padding: 0.6rem 1.6rem;
      font-size: 0.9rem;
    }
    .conheca {
      height: 100%;
      justify-content: center;
    }
    .conheca div {
      padding: 0 !important;
      width: 100%;
    }
  }
  @media (max-width: 568px) {
    display: none;
  }
`;
