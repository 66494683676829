import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '~/components/Footer';
import LocalCatalogo from '~/components/LocalCatalogo';
import BannerCatalogo from '~/components/BannerCatalogo';
import { imgs } from '~/assets/dermoglam';
import setaPrev from '~/assets/icons/seta-prev.gif';
import setaNext from '~/assets/icons/seta-next.gif';
import { Container } from './styles';

function Dermoglam() {
  let pos = 0;

  function next() {
    if (pos + 1 < Array.from(document.querySelectorAll('.info-prod')).length) {
      const width = document.querySelector('.infos').offsetWidth;
      document.querySelector('.infos').scrollLeft += width;
      pos += 1;
      document.querySelector('.seta-prev').style.visibility = 'visible';
      if (
        pos ===
        Array.from(document.querySelectorAll('.info-prod')).length - 1
      ) {
        document.querySelector('.seta-next').style.visibility = 'hidden';
      } else {
        document.querySelector('.seta-prev').style.visibility = 'visible';
      }
    }
  }

  function prev() {
    if (pos - 1 >= 0) {
      const width = document.querySelector('.infos').offsetWidth;
      document.querySelector('.infos').scrollLeft -= width;
      pos -= 1;
      document.querySelector('.seta-next').style.visibility = 'visible';
      if (pos === 0) {
        document.querySelector('.seta-prev').style.visibility = 'hidden';
      } else {
        document.querySelector('.seta-prev').style.visibility = 'visible';
      }
    }
  }
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Anaconda Cosméticos – DermoGlam</title>
          <meta
            name="description"
            content="Conheça a linha DermoGlam da Anaconda. Dermocosméticos desenvolvidos para o tratamento da pele, produtos com efeito lifting, booster e anti-idade."
          />
        </Helmet>
        <div className="row-100 fundo-destaque">
          <div className="container h-100 d-flex">
            <div className="ml-5 mobile-ml-1 my-auto">
              <h2 className="h1 text-uppercase dermoglam">Dermoglam</h2>
              <p className="desc-produto ">
                Conheça a linha DermoGlam da Anaconda. Dermocosméticos
                desenvolvidos para o tratamento da pele, produtos com efeito
                lifting, booster e anti-idade.
              </p>
            </div>
          </div>
        </div>
        <div className="row-100 ">
          <div className="container border bg-white mt-n5 px-4 py-4">
            <h1 className="h2 font-weight-bold text-center py-5 m-auto frase-principal">
              DermoGlam: uma linha completa de dermocosméticos para uma pele
              mais firme e hidratada
            </h1>
          </div>
        </div>
        <div className="row-100 fundo-tarja">
          <div className="container d-flex h-100">
            <h2 className="h6 w-50 text-uppercase text-center m-auto">
              Tratamento para o rejuvenescimento facial, iluminação e
              uniformização da pele
            </h2>
          </div>
        </div>

        <div className="row-100 bg-white border-left border-right border-bottom py-5 mobile-row">
          <div className="container d-flex">
            <button type="button" className="seta-prev my-auto " onClick={prev}>
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>

            <div className="row align-items-center infos">
              <div className="info-prod row px-5">
                <div className="col">
                  <h2 className="h4 font-weight-bold pt-5 pb-4">Lumi glow:</h2>
                  <h3 className="h5 font-weight-bold">
                    Sérum iluminador com efeito bronzeado e shimmer face/body
                  </h3>
                  <p>
                    Hidratante gel iluminador de aplicação fácil e rápida, que
                    aumenta a luminosidade da pele com um brilho dourado
                    delicado e elegante. Sua fórmula combina o efeito iluminador
                    (Glow) com ativos hidratantes que tratam e cuidam da pele.
                  </p>
                  <ul>
                    <li>• Brilho instantâneo</li>
                    <li>• Hidratação intensa</li>
                    <li>• Castor Oil – Panthenol – Vitamina E</li>
                  </ul>
                </div>
                <div className="col d-flex col-img-produto">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.lumiGlow}
                    alt="Dermoglam Lumi Glow"
                  />
                </div>
              </div>
              <div className="info-prod row px-5">
                <div className="col">
                  <h2 className="h4 font-weight-bold pt-5 pb-4">
                    Cover – Base líquida:
                  </h2>
                  <h3 className="h5 font-weight-bold">
                    Desenvolvida para hidratar e uniformizar a pele. Possui alta
                    cobertura com ácido hialurônico e vitamina E.
                  </h3>

                  <ul>
                    <li>• Secagem rápida</li>
                    <li>• Oil free</li>
                    <li>• Resistência à água </li>
                    <li>• Sem parabenos</li>
                    <li>• Dermatologicamente testada</li>
                  </ul>
                  <p>
                    Desenvolvida em três tonalidades: claro, médio e escuro.
                  </p>
                </div>
                <div className="col d-flex col-img-produto">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.baseLiquida}
                    alt="Dermoglam Base Líquida"
                  />
                </div>
              </div>
              <div className="info-prod row px-5">
                <div className="col">
                  <h2 className="h4 font-weight-bold pt-5 pb-4">
                    Gel esfoliante:
                  </h2>

                  <ul>
                    <li>• 100% vegano</li>
                    <li>• Estimula a renovação celular</li>
                    <li>• Limpeza profunda e refrescante</li>
                    <li>• Hidratação intensa</li>
                    <li>
                      • Elimina células mortas sem ressecar e sem agredir a pele
                    </li>
                  </ul>
                </div>
                <div className="col d-flex col-img-produto">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.gelEsfoliante}
                    alt="Dermoglam Gel esfoliante"
                  />
                </div>
              </div>
              <div className="info-prod row px-5">
                <div className="col">
                  <h2 className="h4 font-weight-bold pt-5 pb-4">
                    Máscara Cílios Perfeitos:
                  </h2>

                  <ul>
                    <li>• Alongamento e definição</li>
                    <li>• Pantenol e vitamina E</li>
                    <li>• Fortalece e hidrata</li>
                    <li>• Longa duração</li>
                    <li>• Secagem rápida</li>
                    <li>• Resistente à água</li>
                  </ul>
                </div>
                <div className="col d-flex col-img-produto">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.mascaraCilios}
                    alt="Dermoglam Máscara Cílios Perfeitos"
                  />
                </div>
              </div>
              <div className="info-prod row px-5">
                <div className="col">
                  <h2 className="h4 font-weight-bold pt-5 pb-4">
                    Night Cream:
                  </h2>
                  <h3 className="h5 font-weight-bold">
                    A fórmula para diminuir as rugas e aumentar a resistência da
                    sua pele
                  </h3>
                  <p>
                    Um creme desenvolvido para reduzir as rugas, os sinais de
                    cansaço, as linhas de expressão e aumentar a firmeza e
                    resistência da pele. Night Cream possui uma fórmula
                    inovadora que deixa a pele mais firme e hidratada, além de
                    ter ação antioxidante que deixa a pele com uma aparência
                    mais jovem.
                  </p>

                  <h3 className="h5 font-weight-bold">
                    Benefícios para uma pele glamorosa
                  </h3>
                  <ul>
                    <li>• Anti-idade, hidratação e firmeza.</li>
                    <li>
                      • Reduz rugas, sinais de cansaço e linhas de expressão.
                    </li>
                    <li>• Melhora a firmeza e elasticidade da pele.</li>
                    <li>
                      • Deixa sua pele hidratada e com aspecto mais jovem.
                    </li>
                    <li>• Não faz testes em animais.</li>
                  </ul>
                  <h3 className="h5 font-weight-bold">Veja como usar:</h3>
                  <p>
                    O creme é recomendado para a pele acima dos 25 anos e deve
                    ser usado diariamente no período da noite. O Night Cream é
                    para todos os tipos de pele e não oleoso.
                  </p>
                  <h3 className="h5 font-weight-bold">Composição</h3>
                  <p>
                    Contém: Ácido hialurônico, Colágeno, Vitamina E, Coheliss,
                    QT40, Manteiga de Karité e Óleo de Macadâmia.
                  </p>
                </div>

                <div className="col d-flex col-img-produto">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.nightCream}
                    alt="Dermoglam NightCream"
                  />
                </div>
              </div>
              <div className="info-prod row px-5 ">
                <div className="col">
                  <h2 className="h4 font-weight-bold pt-5 pb-4">
                    Booster Facial:
                  </h2>
                  <h3 className="h5 font-weight-bold">
                    Efeito lifting com mais firmeza e hidratação
                  </h3>
                  <p>
                    Integrado por uma associação de ativos composta com Osolift,
                    Coheliss e Vitamina E, o Booster facial da Anaconda
                    Cosméticos além de reduzir rugas e linhas de expressão, é
                    anti-idade e favorece a firmeza da pele com hidratação e
                    nutrição. Com efeito tensor imediato, o resultado já aparece
                    5 minutos após a aplicação. O efeito lifting tem duração de
                    duas horas e é ideal para ser utilizado como um primer antes
                    da maquiagem.
                  </p>

                  <h3 className="h5 font-weight-bold">
                    Benefícios para uma pele glamorosa
                  </h3>
                  <ul>
                    <li>• Efeito lifting.</li>
                    <li>• Ação anti-idade.</li>
                    <li>• Ação firmadora.</li>
                    <li>• Eficácia comprovada.</li>
                    <li>• Ação antirrugas.</li>
                    <li>• Ação antienvelhecimento.</li>
                    <li>• Dermatologicamente testado.</li>
                    <li>• Sem testes em animais.</li>
                  </ul>
                  <h3 className="h5 font-weight-bold">Veja como usar:</h3>
                  <p>
                    Use diariamente no rosto e pescoço. Após a aplicação é
                    necessário aguardar de 3 a 5 minutos para melhor fixação do
                    produto. O booster possui uma textura leve e não oleosa. É
                    recomendado para todos os tipos de pele.
                  </p>
                  <h3 className="h5 font-weight-bold">Composição</h3>
                  <p>Contém: Vitamina E, Osolift e Coheliss</p>
                </div>
                <div className="col d-flex col-img-produto">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.booster}
                    alt="Dermoglam Booster Efeito Lifting"
                  />
                </div>
              </div>
              <div className="info-prod row px-5">
                <div className="col">
                  <h2 className="h4 font-weight-bold pt-5 pb-4">
                    Sérum Facial:
                  </h2>
                  <h3 className="h5 font-weight-bold">
                    Para uma pele mais jovem, macia e iluminada
                  </h3>
                  <p>
                    O Sérum Facial Anaconda Cosméticos possui um alto poder de
                    revitalização da pele. Por conter ácido hialurônico e
                    Vitamina E em sua composição, o produto mantém a pele macia,
                    jovem e bem hidratada, sem deixá-la oleosa.
                  </p>
                  <h3 className="h5 font-weight-bold">
                    Benefícios para uma pele glamorosa
                  </h3>
                  <ul>
                    <li>• Alto poder hidratante.</li>
                    <li>• Primer iluminador.</li>
                    <li>• Contém Ácido Hialurônico e Vitamina E.</li>
                    <li>• Previne o ressecamento da pele.</li>
                    <li>• Ajuda na fixação da maquiagem.</li>
                    <li>• Proporciona uma pele saudável.</li>
                    <li>• Antioxidante.</li>
                    <li>• Dermatologicamente testado.</li>
                    <li>• Não faz testes em animais.</li>
                  </ul>
                  <h3 className="h5 font-weight-bold">Veja como usar:</h3>
                  <p>
                    Inicie o processo com a pele limpa e seca, aplique algumas
                    gotas do produto por todo o rosto e massageie até a
                    absorção. O Sérum Facial pode ser usado diariamente. Possui
                    textura suave e não oleosa e pode ser usado em todos os
                    tipos de pele.
                  </p>
                  <h3 className="h5 font-weight-bold">Composição</h3>
                  <p> Contém: Ácido hialurônico + Vitamina E</p>
                </div>
                <div className="col d-flex col-img-produto">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.serumFacial}
                    alt="Dermoglam Sérum Facial"
                  />
                </div>
              </div>
              <div className="info-prod row px-5">
                <div className="col">
                  <h2 className="h4 font-weight-bold pt-5 pb-4">
                    Sérum Antioxidante:
                  </h2>
                  <h3 className="h5 font-weight-bold">
                    Poderoso contra os sinais do envelhecimento
                  </h3>
                  <p>
                    O Sérum Antioxidante Anaconda Cosméticos possui em sua
                    composição Vitamina C + Vitamina E que, com a ajuda do ácido
                    hialurônico, diminuem a formação de radicais livres e
                    fortalece a imunidade das células, formando uma poderosa
                    combinação para o combate de rugas, linhas de expressão e
                    ressecamento da pele.
                  </p>
                  <h3 className="h5 font-weight-bold">
                    Benefícios para uma pele glamorosa
                  </h3>
                  <ul>
                    <li>• Ação Antioxidante.</li>
                    <li>• Ação firmadora.</li>
                    <li>• Eficácia comprovada.</li>
                    <li>• Ação antirrugas.</li>
                    <li>• Ácido Hialurônico..</li>
                    <li>• Ação antienvelhecimento.</li>
                    <li>• Dermatologicamente testado.</li>
                    <li>• Não faz testes em animais.</li>
                  </ul>
                  <h3 className="h5 font-weight-bold">Veja como usar:</h3>
                  <p>
                    Aplique por todo o rosto, com a pele limpa e seca, e
                    massageie até a absorção. O Sérum Antioxidante deve ser
                    usado diariamente. E é indicado para todos os tipos de pele.
                  </p>
                </div>
                <div className="col d-flex col-img-produto">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.serumAntiox}
                    alt="Dermoglam Sérum Antioxidante"
                  />
                </div>
              </div>
            </div>

            <button type="button" className="seta-next my-auto" onClick={next}>
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
        </div>
      </Container>
      <BannerCatalogo />
      <LocalCatalogo />
      <Footer />
    </>
  );
}

export default Dermoglam;
