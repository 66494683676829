import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Container } from './styles';
import BannerCatalogo from '~/components/BannerCatalogo';
import img from '~/assets/imagem-localizacao.jpg';
import bannerAmazon from '~/assets/amazon.jpg';
import LocalCatalogo from '~/components/LocalCatalogo';
import Footer from '~/components/Footer';
import api from '../../services/api';

export default function Localizacao() {
  const [clientes, setClientes] = useState([]);
  const [representantes, setRepresentantes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uf, setUf] = useState('');

  useEffect(() => {
    setLoading(true);

    api
      .get(`/clientes/${uf}`)
      .then(response => {
        console.log(response);
        setClientes(response.data.clientes);
        setRepresentantes(response.data.representantes);
      })
      .finally(_ => {
        setLoading(false);
      });
  }, [uf]);

  const changeUf = useCallback(ufSelected => {
    if (ufSelected) {
      setUf(ufSelected);
    }
  }, []);

  const estados = useMemo(
    () => [
      { nome: 'Acre', uf: 'AC' },
      { nome: 'Alagoas', uf: 'AL' },
      { nome: 'Amapá', uf: 'AP' },
      { nome: 'Amazonas', uf: 'AM' },
      { nome: 'Bahia', uf: 'BA' },
      { nome: 'Ceará', uf: 'CE' },
      { nome: 'Distrito Federal', uf: 'DF' },
      { nome: 'Espírito Santo', uf: 'ES' },
      { nome: 'Goiás', uf: 'GO' },
      { nome: 'Maranhão', uf: 'MA' },
      { nome: 'Mato Grosso', uf: 'MT' },
      { nome: 'Mato Grosso do Sul', uf: 'MS' },
      { nome: 'Minas Gerais', uf: 'MG' },
      { nome: 'Pará', uf: 'PA' },
      { nome: 'Paraíba', uf: 'PB' },
      { nome: 'Paraná', uf: 'PR' },
      { nome: 'Pernambuco', uf: 'PE' },
      { nome: 'Piauí', uf: 'PI' },
      { nome: 'Rio de Janeiro', uf: 'RJ' },
      { nome: 'Rio Grande do Norte', uf: 'RN' },
      { nome: 'Rio Grande do Sul', uf: 'RS' },
      { nome: 'Rondônia', uf: 'RO' },
      { nome: 'Roraima', uf: 'RR' },
      { nome: 'Santa Catarina', uf: 'SC' },
      { nome: 'São Paulo', uf: 'SP' },
      { nome: 'Sergipe', uf: 'SE' },
      { nome: 'Tocantins', uf: 'TO' },
    ],
    []
  );

  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Anaconda Cosméticos – Onde encontrar</title>
          <meta
            name="description"
            content="Encontre um representante de cosméticos Anaconda perto de você. Conheça as nossas lojas de revenda."
          />
        </Helmet>
        <div className="row-100 border border-secondary bg-white">
          <div className="container">
            <div className="row py-5 align-items-center d-flex">
              <div className="col-12 col-lg-6">
                <p className="h1 font-weight-bold ">ONDE ENCONTRAR</p>
                <h1 className="subtitulo">
                  Encontre a revenda de cosméticos Anaconda mais próxima de
                  você!
                </h1>

                <h2 className="font-weight-bold my-5 temos-clientes">
                  TEMOS&nbsp;REPRESENTANTES EM TODO O BRASIL
                </h2>
                <form action="#" method="POST" id="form-local">
                  <div className="form-group">
                    <select
                      className="form-control campo my-4"
                      id="local"
                      name="local"
                      placeholder="Digite seu CEP ou Cidade"
                      onChange={e => changeUf(e.target.value)}
                    >
                      <option value="" defaultValue>
                        Selecione um estado
                      </option>
                      {estados.map(estado => (
                        <option key={estados.indexOf(estado)} value={estado.uf}>
                          {estado.nome}
                        </option>
                      ))}
                    </select>
                  </div>
                </form>
              </div>
              <div className="col-12 col-lg-6 d-none d-lg-block">
                <img
                  src={img}
                  alt="Ilustração onde encontrar"
                  className="w-50 mobile-pt-5"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container d-flex my-4 px-5 lista">
          <div className="w-50 mr-4">
            <h4>Lista de clientes perto de você:</h4>
            {console.log(representantes)}
            <ul>
              {loading ? (
                ''
              ) : (
                <>
                  {representantes.length > 0 ? (
                    <li className="font-weight-bold">Representante:</li>
                  ) : (
                    ''
                  )}
                </>
              )}
              {loading ? (
                ''
              ) : (
                <>
                  {representantes.length > 0
                    ? representantes.map(representante => {
                        const { telefones, email } = representante;
                        return (
                          <div
                            key={representantes.indexOf(representante)}
                            className="my-3"
                          >
                            <span className="font-weight-bold">
                              {representante.nome}
                            </span>
                            <ul>
                              {email ? (
                                <li className="text-break">{email}</li>
                              ) : (
                                ''
                              )}
                            </ul>
                            <ul>
                              {telefones
                                ? telefones.map(telefone => (
                                    <li>{telefone.num_tel} </li>
                                  ))
                                : ''}
                            </ul>
                          </div>
                        );
                      })
                    : ''}
                </>
              )}

              {loading ? (
                ''
              ) : (
                <>
                  {clientes.length > 0 ? (
                    <li className="font-weight-bold">Clientes:</li>
                  ) : (
                    ''
                  )}
                </>
              )}
              {loading ? (
                <div className="loader mt-5" />
              ) : (
                <>
                  {clientes.length > 0 &&
                    clientes.map(cliente => {
                      const {
                        site,
                        face,
                        insta,
                        enderecos,
                        telefones,
                      } = cliente;
                      return (
                        <div key={clientes.indexOf(cliente)} className="my-3">
                          <li>
                            • {cliente.nome_cliente}
                            {telefones &&
                              telefones.map(
                                telefone => ` - ${telefone.num_tel}`
                              )}
                          </li>
                          <ul>
                            {site && (
                              <li>
                                <a href={`http://${site}`}>{site}</a>
                              </li>
                            )}
                            {face && (
                              <li>
                                Facebook:<a href={`https://${face}`}>{face}</a>
                              </li>
                            )}
                            {insta && (
                              <li>
                                <a
                                  href={`https://www.instagram.com/${cliente.insta.substring(
                                    1
                                  )}`}
                                >
                                  {insta}
                                </a>
                              </li>
                            )}
                          </ul>
                          {enderecos && (
                            <ul>
                              {enderecos.map(endereco => (
                                <li key={enderecos.indexOf(endereco)}>
                                  {endereco.endereco}
                                  {endereco.telefones && (
                                    <p>
                                      {endereco.telefones.map(
                                        telefone => ` - ${telefone.num_tel}`
                                      )}
                                    </p>
                                  )}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      );
                    })}
                </>
              )}
            </ul>
          </div>
          <div className="w-50 text-center">
            <img
              className="w-100 banner-amazon"
              src={bannerAmazon}
              alt="Banner compre on-line Amazon"
            />
            <a
              href="https://www.amazon.com.br/s?rh=n%3A16194414011%2Cp_4%3AAnaconda&ref=bl_dp_s_web_16194414011"
              className="btn position-relative"
            >
              COMPRAR ONLINE
            </a>
          </div>
        </div>
      </Container>
      <LocalCatalogo />
      <BannerCatalogo />
      <Footer />
    </>
  );
}
