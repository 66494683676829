const batom1 = require('./Batom/1.jpg');
const batom2 = require('./Batom/2.jpg');
const batom3 = require('./Batom/3.png');
const batom4 = require('./Batom/4.jpg');
const batom5 = require('./Batom/5.png');

const olhar1 = require('./olhar/1.jpg');
const olhar2 = require('./olhar/2.png');
const olhar3 = require('./olhar/3.jpg');
const olhar4 = require('./olhar/4.png');
const olhar5 = require('./olhar/5.jpg');

const sobrancelha1 = require('./sobrancelha/1.png');
const sobrancelha2 = require('./sobrancelha/2.png');
const sobrancelha3 = require('./sobrancelha/3.png');
const sobrancelha4 = require('./sobrancelha/4.png');
const sobrancelha5 = require('./sobrancelha/5.png');

const pele1 = require('./Pele/1.jpg');
const pele2 = require('./Pele/2.jpg');
const pele3 = require('./Pele/3.png');
const pele4 = require('./Pele/4.png');
const pele5 = require('./Pele/blush-multiplo-stick.png');
const pele6 = require('./Pele/6.jpg');

export const imgs = {
  batom: [batom1, batom2, batom3, batom4, batom5],
  olhar: [olhar1, olhar2, olhar3, olhar4, olhar5],
  sobrancelha: [
    sobrancelha1,
    sobrancelha2,
    sobrancelha3,
    sobrancelha4,
    sobrancelha5,
  ],
  pele: [pele1, pele2, pele3, pele4, pele5, pele6],
};
